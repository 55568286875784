import type {I18nRequestContext} from '@/types';
import {Page} from '@/utils/server/Page';
import {localizePathBySite} from '@/utils/site';
import {UrlUtils} from '@/utils/UrlUtils';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function main({t, site}: I18nRequestContext) {
  let navItems = [];
  const urlUtils = new UrlUtils(site);

  const editionsAllPage = Page.byPath('/editions/all');

  if (site.features.includes('editions')) {
    navItems.push(
      {
        content: t('nav:editionsLast'),
        url: localizePathBySite('/editions/summer2024', site),
        data: {
          gaEvent: MAIN_NAV_GA_EVENT,
          gaAction: 'Editions',
        },
        description: t('nav:editionsLastDescription'),
        columnSpan: 3,
        icon: 'editions',
      },
      {
        content: t('nav:editionsAll'),
        url: editionsAllPage?.isPublishedOnSite(site)
          ? localizePathBySite('/editions/all', site)
          : '/editions/all',
        data: {
          gaEvent: MAIN_NAV_GA_EVENT,
          gaAction: 'All Editions',
        },
        description: t('nav:editionsAllDescription'),
        columnSpan: 3,
        icon: 'allEditions',
      },
    );
  }

  if (site.languageCode === 'en') {
    navItems.unshift({
      content: t('nav:changelog'),
      url: urlUtils.getUrl('/', 'changelog'),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Changelog',
      },
      description: t('nav:changelogDescription'),
      columnSpan: 3,
      icon: 'changelog',
    });
    navItems.push({
      content: t('nav:newsroom'),
      url: 'https://shopify.com/news',
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Newsroom',
      },
      description: t('nav:newsroomDescription'),
      columnSpan: 3,
      icon: 'newsroom',
    });
  }

  return {
    simple: true,
    columnStart: 1,
    columnSpan: 12,
    navItems,
  };
}
