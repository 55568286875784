// This file is auto generated by Verdict. Do not edit manually.

export type Flag<T> = T & {__brand: 'Flag'};

function flag<FlagType>(fValue: FlagType) {
  return fValue as Flag<FlagType>;
}

export const FLAG_RSI_RETAIL_PLAN_INCENTIVE = flag(
  'f_715c0e3a98dd480376df467707099539',
);
export const FLAG_RSI_POS_PRO_INCENTIVE = flag(
  'f_5a0b4347b30c0f8bd88d91c84ea31be1',
);
export const FLAG_PLUS_1800_HOLIDAY_AU = flag(
  'f_de77e6e530e9662a0d48c31eaa46533b',
);
export const FLAG_PLUS_1800_HOLIDAY_US_CA = flag(
  'f_a32d091a3b1b183267c0400ea9e951aa',
);
export const FLAG_US_DESKTOP_APP_CREDIT = flag(
  'f_7022eba69490e57ba01bbb27c682514c',
);
export const FLAG_DISCOUNTED_PRICE_VARIANT_SPAIN = flag(
  'f_96713d1055c363ddea2ae16668b8f8be',
);
export const FLAG_HOMEPAGE_REDESIGN_USA = flag(
  'f_cb00a0912fee9273bf0cc7e1ec079182',
);
export const FLAG_HOMEPAGE_REDESIGN_PRIORITY = flag(
  'f_6bedf39f2bad34e6818099d6440e3fff',
);
export const FLAG_HOMEPAGE_REDESIGN_GLOBAL = flag(
  'f_e88054d25353c1ef21a86a0893a110a1',
);

type StrongTypeFlags =
  | typeof FLAG_RSI_RETAIL_PLAN_INCENTIVE
  | typeof FLAG_RSI_POS_PRO_INCENTIVE
  | typeof FLAG_PLUS_1800_HOLIDAY_AU
  | typeof FLAG_PLUS_1800_HOLIDAY_US_CA
  | typeof FLAG_US_DESKTOP_APP_CREDIT
  | typeof FLAG_DISCOUNTED_PRICE_VARIANT_SPAIN
  | typeof FLAG_HOMEPAGE_REDESIGN_USA
  | typeof FLAG_HOMEPAGE_REDESIGN_PRIORITY
  | typeof FLAG_HOMEPAGE_REDESIGN_GLOBAL;

export const ALL_FLAGS = [
  'f_715c0e3a98dd480376df467707099539',
  'f_5a0b4347b30c0f8bd88d91c84ea31be1',
  'f_de77e6e530e9662a0d48c31eaa46533b',
  'f_a32d091a3b1b183267c0400ea9e951aa',
  'f_7022eba69490e57ba01bbb27c682514c',
  'f_96713d1055c363ddea2ae16668b8f8be',
  'f_cb00a0912fee9273bf0cc7e1ec079182',
  'f_6bedf39f2bad34e6818099d6440e3fff',
  'f_e88054d25353c1ef21a86a0893a110a1',
] as StrongTypeFlags[];

type ExtractString<T> = T extends Flag<infer S> ? S : never;

export type Flags = StrongTypeFlags | ExtractString<StrongTypeFlags>;
